.item {
    display: flex;
    align-items: center;
}

input[type="text"] {
    width: 100%;
    padding: 5px;
}

.controls {
    display: flex;
    gap: 5px;
}


.arrow {
    width: 20px;
    text-align: center;
}

.bet-row{
    background-color: #242527;
}
